var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.query.invoice_id || _vm.$route.query.task_id)?_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"subheading"},[_vm._v("Filter:")]),(_vm.$route.query.invoice_id)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){_vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {invoice_id: undefined})
        });
        _vm.load();}}},[_vm._v(" Rechnung ")]):_vm._e(),(_vm.$route.query.task_id)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){_vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {task_id: undefined})
        });
        _vm.load();}}},[_vm._v(" Tätigkeit ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mb-2 summary",style:({
      color: _vm.loadingSummaries ? '#b5b5b5' : null
    })},[_vm._v(" Heute: "+_vm._s(this.formatSeconds(_vm.durationToday).substring(0, 5))+" Stunden / "+_vm._s(Math.round(_vm.earningsToday))+" € ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
      {
        text: 'Kunde/Tätigkeit',
        align: 'start',
        sortable: false,
        value: 'customer_name'
      },
      {
        text: 'Tag',
        align: 'start',
        sortable: false,
        value: 'day'
      },
      {
        text: 'Start',
        align: 'start',
        sortable: true,
        value: 'start'
      },
      {
        text: 'Ende',
        align: 'start',
        sortable: true,
        value: 'end'
      },
      {
        text: 'Einnahmen/Dauer',
        align: 'end',
        sortable: true,
        value: 'duration'
      },
      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false
      }
    ],"footer-props":{
      'items-per-page-options': [15, 50, 100]
    },"items":_vm.tracks,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(props){return [(props.item.invoice_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("check")])]}}],null,true)},[_c('span',[_vm._v("Wurde in Rechnung übernommen")])]):_vm._e(),_c('TaskSelection',{on:{"change":function($event){return _vm.updateTask(props.item)}},model:{value:(props.item.task),callback:function ($$v) {_vm.$set(props.item, "task", $$v)},expression:"props.item.task"}}),(props.item.customer)?_c('span',{style:({
          color: props.item.customer.color
        })},[_vm._v(" "+_vm._s(props.item.customer.name)+" : ")]):_vm._e()]}},{key:"item.start",fn:function(props){return [_c('div',{staticClass:"editable"},[_c('input',{staticStyle:{"width":"5em"},attrs:{"type":"text"},domProps:{"value":_vm.$date(new Date(props.item.start), 'HH:mm')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return (function (e) { return _vm.updateTime(e, props.item, 'start'); })($event)},"blur":function (e) { return _vm.updateTime(e, props.item, 'start'); }}})])]}},{key:"item.end",fn:function(props){return [_c('div',{staticClass:"editable"},[_c('input',{staticStyle:{"width":"5em"},attrs:{"type":"text"},domProps:{"value":_vm.$date(new Date(props.item.end), 'HH:mm')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return (function (e) { return _vm.updateTime(e, props.item, 'end'); })($event)},"blur":function (e) { return _vm.updateTime(e, props.item, 'end'); }}})])]}},{key:"item.day",fn:function(props){return [_c('v-menu',{ref:'datePicker_' + props.item.id,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$date(new Date(props.item.start), "dd.MM.yyyy"))+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(props.item.day),callback:function ($$v) {_vm.$set(props.item, "day", $$v)},expression:"props.item.day"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateDay(props.item)}}},[_vm._v(" Speichern ")])],1)],1)]}},{key:"item.duration",fn:function(props){return [(props.item.earnings)?_c('span',{staticStyle:{"color":"#a7a7a7"}},[_vm._v(_vm._s(_vm.formatEarnings(props.item.earnings))+" € |")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatSeconds(props.item.duration))+" ")]}},{key:"item.actions",fn:function(props){return [_c('div',{staticClass:"table-actions"},[_c('v-btn',{attrs:{"icon":"","disabled":!props.item.task,"loading":_vm.startingTrackId === props.item.id},on:{"click":function($event){return _vm.restart(props.item)}}},[_c('v-icon',[_vm._v("play_arrow")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTrack(props.item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }