<template>
  <div>
    <ConnectLexoffice
      v-if="user && !user.lexoffice_connected"
    ></ConnectLexoffice>
    <Tracks></Tracks>
  </div>
</template>

<script>
export default {
  components: {
    ConnectLexoffice: () =>
      import(
        /* webpackChunkName: "ConnectLexoffice" */ "@/components/ConnectLexoffice.vue"
      ),
    Tracks: () =>
      import(/* webpackChunkName: "Tracks" */ "@/components/Tracks.vue")
  },

  computed: {
    user() {
      return this.$store.getters["profile/me"];
    }
  }
};
</script>
