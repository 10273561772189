<template>
  <div>
    <div v-if="$route.query.invoice_id || $route.query.task_id" class="mb-1">
      <span class="subheading">Filter:</span>
      <v-chip
        v-if="$route.query.invoice_id"
        class="ma-2"
        close
        @click:close="
          $router.push({
            query: { ...$route.query, invoice_id: undefined }
          });
          load();
        "
      >
        Rechnung
      </v-chip>
      <v-chip
        v-if="$route.query.task_id"
        class="ma-2"
        close
        @click:close="
          $router.push({
            query: { ...$route.query, task_id: undefined }
          });
          load();
        "
      >
        Tätigkeit
      </v-chip>
    </div>
    <div
      class="mb-2 summary"
      :style="{
        color: loadingSummaries ? '#b5b5b5' : null
      }"
    >
      Heute: {{ this.formatSeconds(durationToday).substring(0, 5) }} Stunden /
      {{ Math.round(earningsToday) }} €
    </div>
    <v-data-table
      :headers="[
        {
          text: 'Kunde/Tätigkeit',
          align: 'start',
          sortable: false,
          value: 'customer_name'
        },
        {
          text: 'Tag',
          align: 'start',
          sortable: false,
          value: 'day'
        },
        {
          text: 'Start',
          align: 'start',
          sortable: true,
          value: 'start'
        },
        {
          text: 'Ende',
          align: 'start',
          sortable: true,
          value: 'end'
        },
        {
          text: 'Einnahmen/Dauer',
          align: 'end',
          sortable: true,
          value: 'duration'
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]"
      :footer-props="{
        'items-per-page-options': [15, 50, 100]
      }"
      :items="tracks"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.customer_name="props">
        <v-tooltip v-if="props.item.invoice_id" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">check</v-icon>
          </template>
          <span>Wurde in Rechnung übernommen</span>
        </v-tooltip>
        <TaskSelection
          v-model="props.item.task"
          @change="updateTask(props.item)"
        ></TaskSelection>
        <span
          v-if="props.item.customer"
          :style="{
            color: props.item.customer.color
          }"
        >
          {{ props.item.customer.name }}
          :
        </span>
      </template>
      <template v-slot:item.start="props">
        <div class="editable">
          <input
            style="width: 5em"
            type="text"
            :value="$date(new Date(props.item.start), 'HH:mm')"
            @keyup.enter.stop.prevent="e => updateTime(e, props.item, 'start')"
            @blur="e => updateTime(e, props.item, 'start')"
          />
        </div>
      </template>
      <template v-slot:item.end="props">
        <div class="editable">
          <input
            style="width: 5em"
            type="text"
            :value="$date(new Date(props.item.end), 'HH:mm')"
            @keyup.enter.stop.prevent="e => updateTime(e, props.item, 'end')"
            @blur="e => updateTime(e, props.item, 'end')"
          />
        </div>
      </template>
      <template v-slot:item.day="props">
        <v-menu
          :ref="'datePicker_' + props.item.id"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{ $date(new Date(props.item.start), "dd.MM.yyyy") }}
            </div>
          </template>
          <v-date-picker v-model="props.item.day" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="updateDay(props.item)">
              Speichern
            </v-btn>
          </v-date-picker>
        </v-menu>
      </template>
      <template v-slot:item.duration="props">
        <span style="color: #a7a7a7" v-if="props.item.earnings"
          >{{ formatEarnings(props.item.earnings) }} € |</span
        >
        {{ formatSeconds(props.item.duration) }}
      </template>
      <template v-slot:item.actions="props">
        <div class="table-actions">
          <v-btn
            icon
            @click="restart(props.item)"
            :disabled="!props.item.task"
            :loading="startingTrackId === props.item.id"
            ><v-icon>play_arrow</v-icon></v-btn
          >
          <v-btn icon @click="deleteTrack(props.item)"
            ><v-icon>delete</v-icon></v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped lang="scss">
.summary {
  text-align: right;
  transition: color 0.5s;
}
</style>

<script>
import { parse, differenceInSeconds } from "date-fns";

export default {
  components: {
    TaskSelection: () =>
      import(
        /* webpackChunkName: "TaskSelection" */ "@/components/TaskSelection.vue"
      )
  },
  data() {
    return {
      startingTrackId: null,
      now: new Date(),
      interval: null,
      options: {
        itemsPerPage: 15
      },
      showDatePicker: false,
      loading: false,
      loadingSummaries: false
    };
  },
  computed: {
    filter() {
      let filter = {};
      if (this.$route.query.customer_id) {
        filter.customer_id = this.$route.query.customer_id;
      }
      if (this.$route.query.task_id) {
        filter.task_id = this.$route.query.task_id;
      }
      if (this.$route.query.invoice_id) {
        filter.invoice_id = this.$route.query.invoice_id;
      }
      return filter;
    },
    durationToday() {
      let duration = this.summaryToday ? this.summaryToday.duration : 0;
      if (this.current_track.start && !this.current_track.end) {
        let x = parse(new Date(this.current_track.start));
        let y = parse(this.now);
        duration += differenceInSeconds(y, x);
      }
      return Math.abs(duration);
    },
    earningsToday() {
      let earnings = this.summaryToday ? this.summaryToday.earnings : 0;
      if (this.current_track.start && !this.current_track.end) {
        let x = parse(new Date(this.current_track.start));
        let y = parse(this.now);
        const currentHours = differenceInSeconds(y, x) / 60 / 60;
        earnings += currentHours * this.current_track.task.hourly_rate;
      }
      return parseFloat(earnings);
    },
    tracks() {
      return this.$store.getters["tracks/list"];
    },
    total() {
      return this.$store.getters["tracks/listTotal"];
    },
    current_track() {
      return this.$store.getters["tracks/current"];
    },
    summaryToday() {
      let summaries = this.$store.getters["summaries/list"];
      if (!summaries) {
        return;
      }
      summaries = summaries.filter(
        s => s.date === this.$date(new Date(), "yyyy-MM-dd")
      );
      return summaries.length ? summaries[0] : null;
    }
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true
    },
    current_track: {
      deep: true,
      handler(track, old_track) {
        if (track.id !== old_track.id) {
          this.load();
        }
      }
    }
  },
  mounted() {
    this.interval = setInterval(this.refreshNow, 60000);
    this.load();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    refreshNow() {
      this.now = new Date();
    },
    loadSummary() {
      this.loadingSummaries = true;
      this.$store
        .dispatch("summaries/list", {
          filter: {
            date_after: this.$date(new Date(), "yyyy-MM-dd"),
            date_before: this.$date(new Date(), "yyyy-MM-dd")
          }
        })
        .then(() => {
          this.loadingSummaries = false;
        });
    },
    updateTask(track) {
      track.task_id = track.task.id;
      this.loading = true;
      this.$store
        .dispatch("tracks/update", track)
        .then(() => {
          this.$store.dispatch("alerts/success", "Tätigkeit angepasst");
          this.load();
        })
        .catch(e => {
          this.loading = false;
          if (e.response) {
            for (const error of e.response.data.errors) {
              this.$store.dispatch("alerts/error", error.detail);
            }
          }
        });
    },
    updateDay(track) {
      this.loading = true;
      if (track.day) {
        const dateParts = track.day.split("-");
        const start = new Date(track.start);
        const end = new Date(track.end);
        start.setDate(dateParts[2]);
        end.setDate(dateParts[2]);
        start.setMonth(dateParts[1] - 1);
        end.setMonth(dateParts[1] - 1);
        start.setYear(dateParts[0]);
        end.setYear(dateParts[0]);
        track.start = this.$date(new Date(start), "yyyy-MM-dd HH:mm:ss");
        track.end = this.$date(new Date(end), "yyyy-MM-dd HH:mm:ss");

        this.$store
          .dispatch("tracks/update", track)
          .then(() => {
            this.$store.dispatch("alerts/success", "Datum angepasst");
            this.load();
          })
          .catch(e => {
            this.loading = false;
            if (e.response) {
              for (const error of e.response.data.errors) {
                this.$store.dispatch("alerts/error", error.detail);
              }
            }
          });
      }
    },
    updateTime(element, track, which) {
      this.loading = true;
      if (!element.srcElement.value.includes(":")) {
        element.srcElement.value =
          element.srcElement.value.substr(0, 2) +
          ":" +
          element.srcElement.value.substr(2, 2) +
          ":" +
          (which === "start" ? "00" : "59");
      }
      const date = this.modifyTime(
        new Date(track[which]),
        element.srcElement.value + ":" + (which === "start" ? "00" : "59")
      );
      if (this.isValidDate(date)) {
        track[which] = this.$date(new Date(date), "yyyy-MM-dd HH:mm:ss");
        this.$store
          .dispatch("tracks/update", track)
          .then(() => {
            this.$store.dispatch("alerts/success", "Uhrzeit angepasst");
            this.load();
          })
          .catch(e => {
            this.loading = false;
            if (e.response) {
              for (const error of e.response.data.errors) {
                this.$store.dispatch("alerts/error", error.detail);
              }
            }
          });
      } else {
        this.loading = false;
        this.$store.dispatch("alerts/error", "Kein Valides Datum/Uhrzeit");
      }
    },
    modifyTime(date, time) {
      const timeParts = time.split(":");
      date.setHours(("00" + parseInt(timeParts[0])).substr(2));
      date.setMinutes(("00" + parseInt(timeParts[1])).substr(2));
      date.setSeconds(("00" + parseInt(timeParts[2])).substr(2));
      return date;
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    load() {
      this.loading = true;
      this.loadingSummaries = true;
      this.$store
        .dispatch("tracks/list", {
          include: "task,task.customer",
          filter: { ...this.filter, ...{ end_not_null: true } },
          page: { number: this.options.page, size: this.options.itemsPerPage },
          sort: this.options.sortBy.length
            ? (this.options.sortDesc[0] ? "-" : "") +
              this.options.sortBy.join(",")
            : null
        })
        .then(() => {
          this.loadSummary();
          this.loading = false;
        });
    },
    async restart(track) {
      this.startingTrackId = track.id;
      await this.$store.dispatch("tracks/add", {
        type: "tracks",
        task_id: track.task.id,
        customer_id: track.customer_id,
        start: this.$date(new Date(), "yyyy-MM-dd HH:mm:ss")
      });
      this.startingTrackId = null;
    },
    async deleteTrack(track) {
      this.$swal
        .fire({
          title:
            "Aufzeichnung für '" +
            track.task.name +
            "' (" +
            this.formatSeconds(track.duration) +
            ") löschen?",
          showCancelButton: true,
          confirmButtonText: `Aufzeichnung löschen`,
          cancelButtonText: `Abbrechen`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$store
              .dispatch("tracks/destroy", track.id)
              .then(() => {
                this.load();
              })
              .catch(() => {
                this.loading = false;
                this.$store.dispatch(
                  "alerts/error",
                  "Konnte nicht gelöscht werden."
                );
              });
          }
        });
    }
  }
};
</script>
